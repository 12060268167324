import { FC, memo } from 'react'
import NextHead from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'

import { siteDescription, siteKeywords, siteName, siteSlogan } from '@/data/settings'
import { HeadProps } from '@/data/types'

const Head: FC<HeadProps> = ({ title, keywords, description, siteName, image }) => {
  const router = useRouter()

  const ogUrl = `${process.env.NEXT_PUBLIC_WEBSITE_SITE_URL}${router.asPath}`
  const ogDefaultImage = image || `${ogUrl}common/assets/images/share-image.jpg`
  const fullTitle = `Barbara Lopez — ${title} `
  const ogImageWidth = 1200
  const ogImageHeight = 630

  return (
    <>
      <NextHead>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <title>{fullTitle}</title>
        <meta name="description" content={description || siteDescription} />
        <meta name="tagline" content={siteSlogan} />
        <meta name="keywords" content={(keywords || siteKeywords).join(', ')} />
        {/* Generate favicons in https://realfavicongenerator.net */}
        <meta name="theme-color" content="#ffffff" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <link rel="apple-touch-icon" sizes="180x180" href="/common/favicons/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/common/favicons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/common/favicons/favicon-16x16.png" />
        <link rel="manifest" href="/common/favicons/site.webmanifest" crossOrigin="use-credentials" />
        <link rel="mask-icon" href="/common/favicons/safari-pinned-tab.svg" color="#000000" />
        <link rel="shortcut icon" href="/common/favicons/favicon.ico" />
        <meta name="msapplication-config" content="/common/favicons/browserconfig.xml" />
        {/* Share meta tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:title" content={fullTitle} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:site_name" content={siteName} />
        <meta property="og:image" content={ogDefaultImage} />
        <meta property="og:image:width" content={ogImageWidth.toString()} />
        <meta property="og:image:height" content={ogImageHeight.toString()} />
        <meta name="twitter:image" content={ogDefaultImage} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Other recommends */}
        <link rel="canonical" href={ogUrl} />

        {/* Analytics Lite */}
        <Script defer async data-host="iambarbaralopez.com" src="https://liteanalytics.com/lite.js" />
        {/* <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            image: {
              '@type': 'ImageObject',
              url: ogDefaultImage,
              height: ogImageHeight,
              width: ogImageWidth
            }
          })}
        </script> */}
      </NextHead>
    </>
  )
}

Head.defaultProps = {
  title: siteName,
  description: siteDescription,
  keywords: siteKeywords
}

export default memo(Head)
